<!-- eslint-disable import/no-cycle -->

<template>

  <b-form>
    <b-row class="justify-content-center">
      <b-col md="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="float-right mb-1"
          onclick="history.back()"
        >
          <feather-icon icon="ArrowLeftCircleIcon" />
          Retour
        </b-button>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col md="12">
            <b-card title="Marchandise">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col
                      class="py-0 my-0"
                      md="12"
                    >
                      <b-form-group
                        label="Catégorie"
                        label-for="hi-first-name"
                        label-cols-md="4"
                        class="p-0 m-0 text-bold"
                      >
                        <b-input-group
                          class="input-group-merge "
                          v-text="offre.categorie_marchandise.libelle"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="py-0 my-0"
                      md="12"
                    >
                      <b-form-group
                        label="Type"
                        label-for="hi-email"
                        label-cols-md="4"
                        class="p-0 m-0 text-bold"
                      >
                        <b-input-group
                          class="input-group-merge"
                          v-text="offre.marchandise.name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="py-0 my-0"
                      md="12"
                    >
                      <b-form-group
                        label="Poids"
                        label-for="hi-email"
                        label-cols-md="4"
                        class="p-0 m-0 text-bold"
                      >
                        <b-input-group
                          class="input-group-merge"
                          v-text="offre.poids_marchandise"
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>
                <b-col
                  md="6"
                  class="px-5"
                >
                  <b-img
                    thumbnail
                    height="90"
                    fluid
                    :src="offre.image_url"
                    :alt="offre.marchandise.name"
                  />
                </b-col>
              </b-row>

            </b-card>

          </b-col>
          <b-col md="6">
            <b-card title="Affreteur">
              <b-row>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Nom"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      {{ offre.affreteur.firstname }} {{ offre.affreteur.lastname }}
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0"
                    label="Email"
                    label-for="hi-email"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.affreteur.email"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    label="Téléphone"
                    class="p-0 m-0"
                    label-for="hi-number"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.affreteur.telephone"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card>

          </b-col>

          <b-col md="6">
            <b-card title="Dates Prévisionnelles">
              <b-row>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Date de départ"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      {{ offre.date_prev_depart }}
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0"
                    label="Date de livraison"
                    label-for="hi-email"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.date_prev_livraison"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card>

          </b-col>

        </b-row>
        <b-row>
          <b-col md="6">
            <b-card title="Départ">
              <b-row>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Pays"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.departement_depart.country.country_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Département"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.departement_depart.name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Ville"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.ville_depart.name"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card>

          </b-col>
          <b-col md="6">
            <b-card title="Destination">
              <b-row>
                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Pays"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.departement_destination.country.country_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Département"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.departement_destination.name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  class="py-0 my-0"
                  md="12"
                >
                  <b-form-group
                    class="p-0 m-0 text-bold"
                    label="Ville"
                    label-for="hi-first-name"
                    label-cols-md="4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      v-text="offre.ville_destination.name"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card>

          </b-col>

        </b-row>

      </b-col>
    </b-row>

  </b-form>

</template>

<script>

import {
  BFormGroup, BCard, BForm, BButton, BRow, BCol, BImg, BInputGroup,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'

// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
    BImg,
    BInputGroup,
  },
  directives: {
    Ripple,

  },
  setup() {
    const {
      getOfferByCode, offre,
    } = useOffres()

    onMounted(async () => {
      await getOfferByCode()
    })

    return {
      offre,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
